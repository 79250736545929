<template>
  <div class="alike-wrapper">
    <div class="alike-title">
      <h3>相似推荐</h3>
    </div>
    <el-row :gutter="20">
      <el-col
        :span="8"
        v-for="(item, index) in list"
        :key="index"
        @click.native="linkToDetail(item)"
      >
        <div class="list-item">
          <div class="item-image">
            <img
              :src="$globalConfig.VUE_APP_IMAGE_URL + item.pic"
              alt
              oncontextmenu="return false"
            />
          </div>
          <div class="item-info">
            <div class="item-content">{{ item.name }}</div>
            <div class="item-footer">
              <div>
                <span>
                  <svg-icon icon-class="view"></svg-icon>
                  {{ item.check }}
                </span>
                <span>
                  <svg-icon icon-class="download"></svg-icon>
                  {{ item.download }}
                </span>
              </div>
              <span>{{ item.creator }}</span>

              <!-- <span
                v-if="isVIP && item.price > 60"
                @click.stop="getAuthorContact"
              >
                <svg-icon icon-class="telephone"></svg-icon>联系作者
              </span>
              <span v-else>
                <svg-icon icon-class="telephone"></svg-icon>购买后联系
              </span> -->
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="联系作者" :visible.sync="modalVisible" width="400px">
      <el-form label-width="100px" label-suffix=":">
        <el-form-item label="手机号码">
          <div
            v-clipboard:copy="String(authorContact.phone)"
            v-clipboard:success="handleCopy"
          >
            {{ authorContact.phone }}
          </div>
        </el-form-item>
        <el-form-item label="微信号">
          <div
            v-clipboard:copy="authorContact.wechat"
            v-clipboard:success="handleCopy"
          >
            {{ authorContact.wechat }}
          </div>
        </el-form-item>
        <el-form-item label="邮箱">
          <div
            v-clipboard:copy="authorContact.email"
            v-clipboard:success="handleCopy"
          >
            {{ authorContact.email }}
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getAuthoContact } from "@/api";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isVIP() {
      return (
        this.$store.getters.userinfo &&
        this.$store.getters.userinfo.vip !== "普通会员"
      );
    },
  },
  data() {
    return {
      authorContact: {},
      modalVisible: false,
    };
  },
  methods: {
    linkToDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {
          id: item.id,
        },
      });
    },
    async getAuthorContact(id) {
      const res = await getAuthoContact({
        authorId: id,
      });
      this.authorContact = res.data;
      this.modalVisible = true;
    },
    handleCopy() {
      this.$message.success("复制成功");
    },
  },
};
</script>

<style lang="scss" scoped>
.alike-title {
  h3 {
    color: #3f3f3f;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
  }
}
.list-item {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background: #fdfdfd;
  padding: 12px;
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  .item-image {
    width: calc(1200px / 6.3);
    flex-shrink: 0;
    height: calc(750px / 6.3);
    overflow: hidden;
    margin-right: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease;
    }
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  .item-info {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .item-content {
    width: 100%;
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .item-footer {
    width: 100%;
    display: flex;
    // text-align: right;
    justify-content: space-between;
    font-size: 16px;
    color: #777;
    div span {
      margin-right: 15px;
    }
    span {
      display: inline-flex;
      align-items: center;
      // margin-left: 15px;
    }
    .svg-icon {
      margin-right: 3px;
    }
  }
}
</style>
