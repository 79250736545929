<template>
  <div class="detail-wrapper">
    <div class="page-block"></div>
    <div class="floor-wrapper">
      <div class="header-wrapper">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>模型详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="search-wrapper">
          <el-input
            placeholder="搜索小区名称"
            v-model="searchForm.searchKey"
            clearable
            class="search-input input-with-select"
          >
            <el-cascader
              v-model="searchForm.city"
              clearable
              :props="{ value: 'id', label: 'name', children: 'son' }"
              :show-all-levels="false"
              slot="prepend"
              placeholder="请选择"
              :options="options"
            ></el-cascader>

            <!-- <el-select v-model="searchForm.city" slot="prepend" placeholder="请选择">
              <el-option
                :label="item.label"
                v-for="item in options"
                :key="item.value"
                :value="item.value"
              ></el-option>
            </el-select> -->
            <div slot="append" class="search-btn" @click="handleSearch">
              <svg-icon icon-class="search"></svg-icon>
            </div>
            <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
          </el-input>
        </div>
      </div>
      <div class="detail-content">
        <div class="left-swiper">
          <img
            :src="$globalConfig.VUE_APP_IMAGE_URL + detail.pic"
            oncontextmenu="return false"
          />
          <!-- <div class="swiper-box">
            <swiper :options="swiperOption" ref="swiperTop">
              <swiper-slide>
                <img src="@/assets/detail-swiper.jpeg" class="swiper-img" />
              </swiper-slide>
              <swiper-slide>
                <img src="@/assets/detail-swiper2.jpeg" class="swiper-img" />
              </swiper-slide>
            </swiper>
            <div class="custom-swiper-button-prev" slot="button-prev">
              <svg-icon icon-class="left"></svg-icon>
            </div>
            <div class="custom-swiper-button-next" slot="button-next">
              <svg-icon icon-class="right"></svg-icon>
            </div>
          </div>
          <div class="thumb-swiper">
            <swiper :options="swiperOption2" ref="swiperThumb">
              <swiper-slide>
                <img src="@/assets/detail-swiper.jpeg" class="swiper-img-thumb" />
              </swiper-slide>
              <swiper-slide>
                <img src="@/assets/detail-swiper2.jpeg" class="swiper-img-thumb" />
              </swiper-slide>
            </swiper>
          </div> -->
        </div>
        <div class="right-info">
          <div class="info-box">
            <div class="info-header">
              <h3>{{ detail.name }}</h3>
              <p>ID:{{ detail.id }}</p>
            </div>
            <div class="info-user">
              <span class="left" v-login @click="getAuthoInfo">
                <svg-icon icon-class="user"></svg-icon>
                {{ detail.creator }}
                <!-- <el-tooltip content="联系作者">
                  <span>
                    <svg-icon icon-class="user"></svg-icon>
                    {{ detail.creator }}
                  </span>
                </el-tooltip> -->
              </span>
              <span class="center">
                <svg-icon icon-class="star"></svg-icon>
                {{ detail.collect }}
              </span>
              <span class="right">
                <svg-icon icon-class="download"></svg-icon>
                {{ detail.download }}
              </span>
            </div>
            <div class="info-center">
              <div class="info-center-item">
                <span>更新时间</span>
                <span>{{ detail.CreationTime }}</span>
              </div>
              <div class="info-center-item">
                <span>文件大小</span>
                <span>{{ Number((detail.FileSize / 1024).toFixed(2)) }}M</span>
              </div>

              <div class="info-center-item">
                <span>类别</span>
                <span>{{ detail.TypeName }}</span>
              </div>
              <div class="info-center-item">
                <span>地区</span>
                <span>{{ detail.AreaName }} - {{ detail.SiteName }}</span>
              </div>
              <div class="info-center-item">
                <span>描述</span>
                <span>{{ detail.description }}</span>
              </div>
              <!-- <div class="info-center-item">
                <span>风格</span>
                <span>{{ detail.style }}</span>
              </div>
              <div class="info-center-item">
                <span>贴图</span>
                <span>{{ detail.mapping ? '有' : '无' }}</span>
              </div>
              <div class="info-center-item">
                <span>VR/CR</span>
                <span>{{ detail.type }}</span>
              </div>
              <div class="info-center-item">
                <span>MAX版本</span>
                <span>{{ detail.max }}</span>
              </div> -->
            </div>
            <div class="info-footer">
              <div class="price">
                <div>
                  <span>官方指导价</span>
                  <div>
                    <span>{{ detail.money }}</span> 硬币
                  </div>
                </div>
                <div>
                  <span>VIP专享</span>
                  <div>
                    <span>{{ detail.VipMoney }}</span> 硬币
                  </div>
                </div>
              </div>
              <el-button
                class="block large"
                size="medium"
                type="primary"
                v-login
                @click="handleDownload"
                >立即下载</el-button
              >
              <div class="feedback">
                <!-- <span class="left">
                  <svg-icon icon-class="likes" ></svg-icon>点赞
                </span> -->
                <span class="center" v-login @click="addLike" v-if="!isCollect">
                  <!-- <i class="el-icon-star-off"></i>收藏 -->
                  <svg-icon icon-class="star"></svg-icon>收藏
                </span>
                <span class="center" v-else v-login @click="delLike">
                  <!-- <i class="el-icon-star-off"></i>收藏 -->
                  <svg-icon icon-class="star-on"></svg-icon>已收藏
                </span>
                <span class="right">
                  <svg-icon icon-class="feedback"></svg-icon>纠错
                </span>
              </div>
            </div>
          </div>
          <!-- <div class="author-box">
            <div class="author-header">
              <el-avatar :src="author.avatar">头像</el-avatar>
              <div>
                <p>{{ author.nickname }}</p>
                <span>作品: {{ author.portfolioCount }}</span>
              </div>
              <el-button size="mini" round>查看全部</el-button>
            </div>
            <div class="author-works">
              <img
                :src="item.image"
                :key="item.image"
                v-for="item in author.portfolio"
                class="work-img"
              />
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="floor-wrapper">
      <Alike :list="list"></Alike>
    </div>
    <el-dialog title="联系作者" :visible.sync="modalVisible" width="400px">
      <el-form label-width="100px" label-suffix=":">
        <el-form-item label="qq">
          <div
            v-clipboard:copy="String(authorContact.qq)"
            v-clipboard:success="handleCopy"
          >
            {{ authorContact.qq }}
          </div>
        </el-form-item>
        <el-form-item label="手机号">
          <div
            v-clipboard:copy="authorContact.phone"
            v-clipboard:success="handleCopy"
          >
            {{ authorContact.phone }}
          </div>
        </el-form-item>
        <el-form-item label="邮箱">
          <div
            v-clipboard:copy="authorContact.email"
            v-clipboard:success="handleCopy"
          >
            {{ authorContact.email }}
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      :visible.sync="payDialog"
      @close="beforeClose"
      width="600px"
      title="商品购买"
      v-if="userinfo"
    >
      <el-form label-width="80px" size="mini">
        <el-form-item label="订单编号">
          {{ orderNo }}
        </el-form-item>
        <el-form-item label="商品名称">
          {{ detail.name }}
        </el-form-item>
        <el-form-item label="价格">
          <div>
            {{ detail.money }}硬币
            <span class="vip-price">
              VIP专享价:
              <b>{{ detail.VipMoney }}</b
              >硬币
            </span>
          </div>
        </el-form-item>
        <el-form-item label="我的硬币">
          {{ userinfo.money }}
        </el-form-item>
        <!-- <el-form-item label="积分抵扣">
          <el-checkbox
            v-model="isUseScore"
            @change="createOrder"
          >使用 {{ Math.floor(userinfo.score / 10) * 10 }} 积分抵扣 {{ Math.floor(userinfo.score / 10) }} 硬币</el-checkbox>
        </el-form-item> -->
      </el-form>
      <div class="price-qr">
        <el-button
          type="primary"
          v-if="payType === 'yingbi'"
          @click="payWithCoin"
          >立即购买</el-button
        >
        <template v-else>
          <vue-qr :text="orderPath" :size="200"></vue-qr>
        </template>
        <div>
          ￥
          <b>{{ money }}</b>
        </div>
        <p>
          支付方式
          <el-radio-group v-model="payType" @change="createOrder">
            <el-radio label="yingbi">硬币</el-radio>
            <el-radio label="wechat">微信</el-radio>
            <!-- <el-radio label="alipay">支付宝</el-radio> -->
          </el-radio-group>
        </p>
        <!-- <p>支持微信/支付宝</p> -->
        <p class="tips">已购买的素材在30天内可重复下载</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Alike from "../components/alike.vue";
import { provinceAndCityDataPlus } from "element-china-area-data";
import {
  getModelDetail,
  getModelRecommond,
  addRecharge,
  addLike,
  delLike,
  getCity,
  getIsCollect,
  getModelUrl,
  queryOrderState,
  payCoin,
  getAuthoContact,
} from "../api";
import { mapGetters } from "vuex";
import VueQr from "vue-qr";
export default {
  data() {
    return {
      searchForm: {
        searchKey: "",
        city: "",
      },
      options: provinceAndCityDataPlus,
      swiperOption: {
        // slidesPerView: 2,
        // 设置前进后退按钮
        navigation: {
          nextEl: ".custom-swiper-button-next",
          prevEl: ".custom-swiper-button-prev",
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000, // 5秒切换一次
        },
        // 设置轮播可循环
        loop: false,
      },
      swiperOption2: {
        loop: false,
        slidesPerView: 4,
        // slidesPerView: 4,
        spaceBetween: 20,
        slideToClickedSlide: true,
      },
      list: [],
      detail: {},
      payDialog: false,
      orderPath: "",
      isUseScore: false,
      isCollect: false,
      downloadPath: "",
      payType: "yingbi",
      orderNo: "",
      money: "",
      modalVisible: false,
      authorContact: {
        qq: "",
        wechat: "",
        email: "",
      },
    };
  },
  components: { Alike, VueQr },
  computed: {
    ...mapGetters(["userinfo"]),
    totalMoney() {
      if (this.userinfo?.vip !== "普通会员") {
        return (
          this.detail?.vipPrice -
          (this.isUseScore ? Math.floor(this.userinfo?.score / 10) : 0)
        );
      }
      return (
        this.detail?.price -
        (this.isUseScore ? Math.floor(this.userinfo?.score / 10) : 0)
      );
    },
    author() {
      return this.detail?.author || {};
    },
  },
  watch: {
    $route: {
      handler() {
        this.getData();
        // this.getRecommond()
      },
    },
  },
  mounted() {
    this.$store.dispatch("getMessage");
    this.getIsCollect();
    // this.getDownloadPath();
    this.getCity();
    this.getData();
    // this.getRecommond()
  },
  methods: {
    async getAuthoInfo() {
      if (!this.userinfo) return;
      const res = await getAuthoContact({
        id: this.$route.query.id,
      });
      this.modalVisible = true;
      this.authorContact = res;
    },
    handleCopy() {
      this.$message.success("复制成功");
    },
    beforeClose() {
      clearTimeout(this.timer);
      this.timer = null;
      this.orderNo = "";
    },
    async payWithCoin() {
      payCoin({
        OrderNo: this.orderNo,
      }).then(() => {
        this.queryState();
      });
    },
    async queryState() {
      if (!this.orderNo) return;
      clearTimeout(this.timer);
      queryOrderState({
        OrderNo: this.orderNo,
      }).then((res) => {
        // if ()
        if (res === "unpaid") {
          this.timer = setTimeout(() => {
            this.queryState();
          }, 500);
        } else if (res === "success") {
          this.$message.success("支付成功");
          location.reload();
          // this.download();
        } else if (res === "pastdue") {
          this.createOrder();
        }
      });
    },
    async getCity() {
      const res = await getCity({});
      console.log("res >>", res);
      this.options = res;
    },
    async getIsCollect() {
      if (!this.userinfo) return;
      const res = await getIsCollect({
        gid: this.$route.query.id,
      });
      this.isCollect = res;
      console.log("res >>", res);
    },
    async getDownloadPath() {
      if (!this.userinfo) return;
      const res = await getModelUrl({
        id: this.$route.query.id,
      });
      this.downloadPath = res;
      console.log("res >>", res);
    },
    async createOrder() {
      clearTimeout(this.timer);
      const res = await addRecharge({
        // money: this.totalMoney
        id: this.detail.id,
        type: this.payType,
      });
      this.orderPath = res.code;
      this.orderNo = res.OrderNo;
      this.money = res.money;
      if (this.payType !== "yingbi") {
        this.$nextTick(() => {
          this.queryState();
        });
      }
    },
    async getData() {
      const res = await getModelDetail({
        id: this.$route.query.id,
      });
      this.detail = res;
      this.typeId = res.typeId;
      // this.$nextTick(() => {
      //   const swiperTop = this.$refs.swiperTop.$swiper;
      //   const swiperThumb = this.$refs.swiperThumb.$swiper;
      //   console.log(
      //     "this.$refs.swiperTop >>",
      //     this.$refs.swiperTop,
      //     this.$refs.swiperThumb
      //   );
      //   swiperTop.controller.control = swiperThumb;
      //   swiperThumb.controller.control = swiperTop;
      // });
      this.getRecommond();
    },
    handleSearch() {
      this.$router.push({
        path: "/list",
        query: {
          ...this.searchForm,
        },
      });
    },
    async getRecommond() {
      const res = await getModelRecommond({
        page: 1,
        pagenum: 10,
        TypeID: this.detail.TypeID,
        id: this.detail.id,
      });
      this.list = res;
      // this.total = res.pageMeta.total
    },
    async handleDownload() {
      if (!this.$store.state.userinfo) {
        return false;
      }
      const res = await getModelUrl({
        id: this.$route.query.id,
      });
      this.downloadPath = res;

      if (!this.downloadPath) {
        this.orderNo = "";
        this.orderPath = "";
        this.createOrder();
        this.payDialog = true;
        this.isUseScore = false;
      } else {
        this.download();
      }
    },
    addLike() {
      if (!this.$store.state.userinfo) {
        return false;
      }
      addLike({
        type: this.detail.TypeID,
        gid: this.detail.id,
      }).then(() => {
        this.$message.success("收藏成功");
        this.getIsCollect();
      });
    },
    delLike() {
      if (!this.$store.state.userinfo) {
        return false;
      }
      delLike({
        // type: this.detail.TypeID,
        id: this.isCollect,
      }).then(() => {
        this.$message.success("取消成功");
        this.getIsCollect();
      });
    },
    download() {
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = this.$globalConfig.VUE_APP_IMAGE_URL + this.downloadPath;
      document.body.appendChild(a);
      // a.target = "_blank";
      // a.download = fileName + '.zip';
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-content {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  .left-swiper {
    width: 960px;
    height: 580px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    overflow: hidden;
    img {
      width: 100%;
      max-height: 100%;
    }
  }
  .swiper-box {
    position: relative;
    .swiper-img {
      width: 100%;
    }

    .custom-swiper-button-prev,
    .custom-swiper-button-next {
      font-size: 24px;
      position: absolute;
      top: calc(50% - 30px);
      z-index: 1;
      cursor: pointer;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      color: rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      &.swiper-button-disabled {
        background: rgba(0, 0, 0, 0.1) !important;
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }
    &:hover {
      .custom-swiper-button-prev,
      .custom-swiper-button-next {
        background: rgba(0, 0, 0, 0.5);

        color: #f0f0f0;
      }
    }
    .custom-swiper-button-prev {
      left: 10px;
    }
    .custom-swiper-button-next {
      right: 10px;
    }
  }
  .info-box {
    width: 460px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    height: 580px;
    padding: 20px;
    .info-header {
      h3 {
        margin: 0;
        padding: 0;
        font-size: 24px;
      }
      p {
        font-size: 18px;
        margin-top: 12px;
        font-weight: bold;
      }
    }
    .info-user {
      padding: 15px 0;
      font-size: 16px;
      color: #777;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > span {
        display: inline-flex;
        align-items: center;
        // width: 120px;
        .center {
          text-align: center;
        }
        .right {
          text-align: right;
        }
        .left {
          cursor: pointer;
        }
        // :v-deep .left span {
        //   display: inline-flex;
        //   align-items: center;
        // }
        .svg-icon {
          margin-right: 6px;
        }
      }
      .left {
        cursor: pointer;
        // text-decoration: underline;
      }
    }
    .info-center {
      height: 220px;
      border-top: 1px dashed #ededed;
      border-bottom: 1px dashed #ededed;
      padding: 20px 0;
      .info-center-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        span {
          flex-shrink: 0;
          max-width: 70%;
          &:first-child {
            color: #777;
            // padding-right: 10px;
          }
        }
      }
    }
    .info-footer {
      padding-top: 20px;
      .price {
        display: flex;
        justify-content: space-between;
        > div {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #ffedf0;
          padding: 15px;
          &:first-child {
            color: rgba(248, 0, 0, 0.6);
            position: relative;
            &::after {
              display: block;
              content: "";
              position: absolute;
              width: 8px;
              height: 115px;
              background: #fff;
              right: 0;
              top: -18px;
              transform: rotate(-45deg);
            }
          }
          &:last-child {
            color: #faad14;
          }
          > div {
            margin-top: 6px;
            span {
              font-size: 20px;
              font-weight: bold;
            }
          }
        }
        margin-bottom: 20px;
      }
    }
    .feedback {
      padding: 15px 0;
      font-size: 16px;
      color: #777;
      display: flex;
      align-items: center;
      // justify-content: center;
      justify-content: space-around;
      > span {
        display: inline-flex;
        align-items: center;
        width: 120px;
        justify-content: center;
        text-align: center;
        .svg-icon {
          margin-right: 6px;
        }
      }
      .center {
        cursor: pointer;
      }
    }
  }
  .author-box {
    margin-top: 20px;
    width: 460px;
    height: 240px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    .author-header {
      display: flex;
      align-items: center;
      div {
        width: 0;
        flex: 1;
        margin-left: 12px;
        p {
          margin-bottom: 8px;
        }
        span {
          font-size: 14px;
          color: #777;
        }
      }
    }
    .author-works {
      display: flex;
      overflow: hidden;
      padding-top: 30px;
      position: relative;
      &::after {
        width: 30px;
        height: 100%;
        position: absolute;
        display: block;
        content: "";
        right: 0;
        background: linear-gradient(
          270deg,
          #fff 0%,
          rgba(255, 255, 255, 0.2) 100%
        );
      }
    }
    .work-img {
      width: 180px;
      height: auto;
      margin-right: 20px;
      border-radius: 4px;
    }
  }
}
.search-wrapper {
  .search-input {
    width: 460px;
    // margin-left: 35px;
    padding: 6px 0;
    border-radius: 4px;
    background-color: #fff;
    overflow: hidden;
    // border-bottom: 2px solid #e80000;
    ::v-deep {
      .el-input-group__prepend {
        background: transparent;
        border: none;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          height: 60%;
          width: 1px;
          background: #bbb;
          right: 0;
          top: 20%;
        }
      }
      .el-input--suffix {
        width: 90px;
      }
      .el-input__inner {
        background: transparent;
        border: none;
        // color: #f0f0f0;
      }
      .el-input-group__append {
        background: transparent;
        border: none;
        color: #fff;
      }
      .search-btn {
        // width: 32px;
        padding: 10px 24px;
        margin: -9px -20px;
        font-size: 20px;
        cursor: pointer;
        color: #fff;
        background: #f80000;
        height: 48px;
      }
    }
  }
}
.thumb-swiper {
  margin-top: 25px;
  ::v-deep {
    .swiper-slide {
      width: 200px;
      font-size: 0;
      border: 2px solid transparent;
      &.swiper-slide-active {
        border-color: #f80000;
      }
    }
  }
}
.swiper-img-thumb {
  // width: 180px;
  width: 100%;
  cursor: pointer;
}
.tips {
  color: #999;
  margin-top: 5px;
}
.vip-price {
  display: inline-block;
  margin-left: 15px;
  border: 1px solid #facccc;
  background-color: #fde6e6;
  border-radius: 12px;
  padding: 0 10px;
  color: #111;
  b {
    margin: 0 3px;
    color: #f80000;
  }
}
.price-qr {
  text-align: center;
  div {
    color: #f80000;
    padding: 5px 0;
    b {
      font-size: 24px;
    }
  }
}
</style>
